// REACT HOOK FORM VALIDATIONS

export const rhfValidations = (fieldName, requiredMessage, validValueMsg) => {
  const defaultValidation = (message) => ({
    required: {
      value: true,
      message,
    },
  });

  if (fieldName?.includes('name')) {
    return {
      ...defaultValidation(requiredMessage),
      pattern: {
        value: /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/,
        message: validValueMsg,
      },
    };
  }

  if (fieldName?.includes('email')) {
    return {
      ...defaultValidation(requiredMessage),
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: validValueMsg,
      },
    };
  }

  if (fieldName?.includes('phone')) {
    return {
      ...defaultValidation(requiredMessage),
      pattern: {
        value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
        message: validValueMsg,
      },
    };
  }
};
