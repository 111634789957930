import React from 'react';
import { Section, Wrapper, Container, Icon, Heading, Link } from 'components';
import './FeaturesImages.scss';

const Feature = (items) =>
  items.map((item, index) => {
    const { icon, title, description, link } = item;
    const feature = (
      <div key={index} className="feature-item-container">
        <div className="feature-icon">
          <Icon title={icon} />
        </div>
        <div className="feature-title">{title.text}</div>
        <div className="feature-description">{description.text}</div>
      </div>
    );

    const featureWithLink = (
      <Link
        key={index}
        indicator
        className="feature-item feature-item-link"
        to={`/${link && link.type}/${link && link.uid}`}
      >
        <Link.Indicator />
        {feature}
      </Link>
    );

    return link && link.uid ? featureWithLink : feature;
  });

export const FeaturesImages = ({ items, data }) => {
  const { title, subtitle, description } = data;
  return (
    <Section className="features-images">
      <Wrapper className="features-wrapper">
        <Heading title={title} subtitle={subtitle} description={description} />
        <Container className="features-items">{Feature(items)}</Container>
      </Wrapper>
    </Section>
  );
};
