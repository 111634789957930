import React, { useRef, useEffect, useState } from 'react';
import { Section, Wrapper, Heading, FormField } from 'components';
import { useForm, FormProvider } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import axios from 'axios';
import dayjs from 'dayjs';
import { MapForm } from './MapForm';
import { rhfValidations } from '../../tools/helpers/rhfValidations';
import './Form.scss';

const IS_DEV = process.env.NODE_ENV === 'development';

const ContactForm = (props) => {
  const formMethods = useForm({ mode: 'onSubmit', reValidateMode: 'onBlur' });
  const { register, handleSubmit, formState, control, setError } = formMethods;
  const { isSubmitting, errors } = formState;
  const { type: formType, title } = props;

  const onSubmit = async (formData) => {
    const { _gotcha, ...sanitisedFormData } = formData;

    try {
      const isJobApplication = formType === 'Job Application';
      const api = isJobApplication ? 'job-applications' : 'sales-enquiries';
      let mondayBody = null;

      if (isJobApplication) {
        const fileformdata = new FormData();
        const expiryDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString();

        fileformdata.append('file', sanitisedFormData.resume);
        fileformdata.append('expires', expiryDate);
        fileformdata.append('maxDownloads', '1');
        fileformdata.append('autoDelete', 'true');

        const fileURL = await axios.post('https://file.io/', fileformdata, {
          headers: {
            'Content-Type': ' multipart/form-data',
            Authorization: `Bearer ${process.env.GATSBY_FILE_IO_API_KEY}`,
          },
        });

        mondayBody = { ...sanitisedFormData };
        mondayBody.title = title?.text || 'General Application';
        mondayBody.resume = `${process.env.GATSBY_SITE_URL}/download?link=${fileURL?.data?.link}` || null;
      }

      if (!mondayBody) mondayBody = { ...sanitisedFormData };

      // update sales board enquiries - monday.com
      // eslint-disable-next-line block-scoped-var
      const mondayResponse = await axios.post(`${process.env.GATSBY_SITE_URL}/api/${api}`, mondayBody, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (isJobApplication && mondayResponse) {
        return navigate('/thank-you');
      }

      // // contact@cleanvibes.com.au formSpark
      if (!isJobApplication) {
        const url = `https://submit-form.com/Z2Xt4FyV`;
        const response = await axios.post(url, sanitisedFormData);
        if (response) {
          return navigate('/thank-you');
        }
      }

      // failed to send
    } catch (error) {
      console.error('Error submitting form', error);
      setError('formSubmission', {
        type: 'manual',
        message: 'Oops something went wrong, please try again',
      });
    }
  };

  const defaultValidation = (validationMessage) => ({
    required: {
      value: true,
      message: validationMessage,
    },
  });

  const submissionError = errors?.formSubmission;

  const contactForm = [
    {
      name: 'fullname',
      className: 'form-half',
      label: 'Full name*',
      validation: rhfValidations('fullname', 'Full name is required', 'Please enter valid name'),
    },
    {
      name: 'company',
      className: 'form-half',
      label: 'Company name*',
      validation: defaultValidation('Company is required'),
    },
    {
      name: 'patronAttendees',
      className: 'form-half',
      label: 'Patron Attendees*',
      type: 'number',
      min: 0,
      max: 1000000,
      validation: defaultValidation('Number of patron attendees is required'),
    },
    {
      name: 'typeOfEvent',
      className: 'form-half',
      label: 'Type of Event*',
      type: 'select',
      placeholder: 'Select',
      options: ['Live/Public Event', 'Local/Community', 'Major Event', 'Other'],
      validation: defaultValidation('Type of event is required'),
    },
    {
      name: 'state',
      className: 'form-half',
      label: 'State*',
      type: 'select',
      placeholder: 'Select',
      options: ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'ACT', 'NT'],
      validation: defaultValidation('State is required'),
    },
    {
      name: 'eventDate',
      className: 'form-half',
      label: 'Event Date*',
      type: 'date',
      min: dayjs().format('YYYY-MM-DD'),
      max: '2032-01-01',
      validation: defaultValidation('State is required'),
    },
    {
      name: 'email',
      className: 'form-half',
      label: 'Email*',
      type: 'email',
      validation: rhfValidations('email', 'Email is required', 'Please enter valid email'),
    },
    {
      name: 'phone',
      className: 'form-full',
      label: 'Phone*',
      type: 'phone',
      validation: rhfValidations('phone', 'Phone number is required', 'Please enter valid number'),
    },
    {
      name: 'referral',
      className: 'form-full',
      label: 'How did you hear about us?',
      type: 'select',
      placeholder: 'Select',
      options: ['Social Media', 'Word of Mouth', 'Attended an Event', 'Google Search'],
    },
  ];

  /**
   * Job application form fields
   * Will need:
   *
   * First name
   * Last name
   * Email
   * Phone number
   * Position (Auto-fill from Button Grid)
   * Resume (Upload)
   */

  const jobApplicationForm = [
    {
      name: 'firstname',
      className: 'form-full',
      label: 'First name*',
      validation: rhfValidations('fullname', 'Name is required', 'Please enter valid name'),
    },
    {
      name: 'lastname',
      className: 'form-full',
      label: 'Last name*',
      validation: rhfValidations('fullname', 'Name is required', 'Please enter valid name'),
    },
    {
      name: 'email',
      className: 'form-full',
      label: 'Email*',
      type: 'email',
      validation: rhfValidations('email', 'Email is required', 'Please enter valid email'),
    },
    {
      name: 'phone',
      className: 'form-full',
      label: 'Phone*',
      type: 'phone',
      validation: rhfValidations('phone', 'Phone number is required', 'Please enter valid number'),
    },
    {
      name: 'state',
      className: 'form-half',
      label: 'State*',
      type: 'select',
      placeholder: 'Select',
      options: ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'ACT', 'NT'],
      validation: defaultValidation('State is required'),
    },
    {
      name: 'postcode',
      className: 'form-full',
      label: 'Postcode*',
      validation: rhfValidations('postcode', 'Postcode is required', 'Please enter valid postcode'),
    },
    {
      name: 'resume',
      className: 'form-full',
      type: 'fileupload',
      validation: {
        required: false,
        validate: {
          lessThan10MB: (files) => !files[0] || files[0]?.size < 10000000,
          acceptedFormats: (files) =>
            !files[0] || ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'].includes(files[0]?.type),
        },
      },
      validationMessage: 'File needs to be less than 10mb and a PNG, PDF or JPG',
      label: 'Upload your Resume*',
    },
  ];

  const formFields = formType === 'Job Application' ? jobApplicationForm : contactForm;

  return (
    <FormProvider {...formMethods}>
      <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
        {submissionError?.message && <p className="form-error-msg">{submissionError?.message}</p>}
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        {/* SPAM PROTECTION */}
        <input
          {...register('_gotcha')}
          type="checkbox"
          name="_gotcha"
          style={{ display: 'none' }}
          tabIndex="-1"
          autoComplete="off"
        />
        {formFields?.map((formField) => (
          <FormField key={formField?.name} {...formField} />
        ))}
        <button type="submit" className="link button primary lg" disabled={isSubmitting}>
          Submit
        </button>
        {/* IS_DEV && <DevTool control={control} /> */}
      </form>
    </FormProvider>
  );
};

export const Form = ({ data, selectedItem }) => {
  const { title, subtitle, submit, form } = data;

  const content = useStaticQuery(graphql`
    query CoordinatesQuery {
      site {
        siteMetadata {
          marker
        }
      }
      location: allPrismicLocation {
        nodes {
          uid
          type
          data {
            title {
              text
            }
            location {
              coordinates {
                lat: latitude
                lng: longitude
              }
              name
            }
          }
        }
      }
      project: allPrismicProject {
        nodes {
          uid
          type
          data {
            title {
              text
            }
            location {
              coordinates {
                lat: latitude
                lng: longitude
              }
              name
            }
          }
        }
      }
    }
  `);

  const ref = useRef(null);
  const [mapHeight, setMapHeight] = useState(null);

  useEffect(() => {
    setMapHeight(ref?.current?.clientHeight);
  }, []);

  const hasLocations = content.location.nodes.length > 0;
  const isJobApplication = form === 'Job Application';

  const headingTitle = isJobApplication ? { text: 'Job Application' } : title;
  const headingSubtitle = isJobApplication
    ? selectedItem?.job_title?.raw[0] || { text: 'General Application' }
    : subtitle;

  return (
    <Section id="contact" className="form">
      {!isJobApplication && (
        <div ref={ref} className="form-map">
          {hasLocations && mapHeight !== null && <MapForm height={mapHeight} content={content} />}
          {!hasLocations && <div className="form-image" style={{ backgroundImage: 'url(/image.jpg)' }} />}
        </div>
      )}
      <Wrapper className={`${isJobApplication && 'jobApplication'} ${'form-contact'}`}>
        <Heading className="vertical" title={headingTitle} subtitle={headingSubtitle} />
        <ContactForm type={form} submit={submit} title={headingSubtitle} />
      </Wrapper>
    </Section>
  );
};
