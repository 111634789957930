import React from 'react';
import { Section, Wrapper, Carousel, Image, Heading, Icon } from 'components';
import { loop } from 'helpers';
import './Process.scss';

const ItemDisplay = (props) => {
  if (!props) return null;
  const { title, description, index } = props;
  return (
    <div className="process-item">
      <div className="item-heading">
        <div className="item-index">{index + 1}</div>
        <div className="item-line" />
      </div>
      <div className="item-title">{title && title.text}</div>
      <div className="item-description">{description && description.text}</div>
    </div>
  );
};

export const Process = ({ data, items }) => {
  const { title, subtitle, description } = data;
  return (
    <Section className="process">
      <Wrapper className="process-heading">
        <Heading title={title} subtitle={subtitle} description={description} />
      </Wrapper>
      <div className="process-items">
        <Carousel className="overflow" layout="Multi">
          {loop(items, (item) => ItemDisplay(item))}
        </Carousel>
      </div>
    </Section>
  );
};
