import React from 'react';
import { Section, Wrapper, Heading, Image, Link } from 'components';
import CleanVibesHand from 'images/clean-vibes.png';
import './Grid.scss';

const GridItem = (item) => {
  const { image, card_title: cardTitle, card_subtitle: cardSubtitle, card_content: cardContent } = item;
  return (
    <div className="grid-item">
      {image?.url && <Image className="grid-item-image" image={image} />}
      <div>
        {cardTitle?.text && <h4 className="grid-item-title">{cardTitle.text}</h4>}
        {cardSubtitle?.text && <p className="grid-item-subtitle">{cardSubtitle.text}</p>}
      </div>
      {cardContent?.html && (
        <div className="grid-item-content" dangerouslySetInnerHTML={{ __html: cardContent.html }} />
      )}
    </div>
  );
};

export const Grid = ({ data, items }) => {
  const {
    grid_tagline: gridTagline,
    grid_title: gridTitle,
    closing_cta_title: closingCtaTitle,
    closing_cta_button_text: closingCtaButtonText,
    closing_cta_button_link: closingCtaButtonLink,
  } = data;

  const showGridTop = gridTagline.text || gridTitle.text || closingCtaTitle.text;
  const showGridBottom = closingCtaTitle.text && closingCtaButtonText.text;

  return (
    <Section className="grid-section">
      <Wrapper className="grid-wrapper">
        {/* Render the non-repeat fields */}
        {showGridTop && (
          <div className="grid-top">
            <Heading className="grid-top-heading" title={gridTagline} subtitle={gridTitle} />
          </div>
        )}

        {/* Render the repeatable items */}
        <div className="grid-items">
          {items.map((item) => (
            <GridItem key={item.card_title.text} {...item} />
          ))}
        </div>

        {showGridBottom && (
          <div className="grid-bottom">
            <img className="grid-closing-cta-img" src={CleanVibesHand} alt="Clean Vibes" />
            <h5 className="grid-closing-cta-title">{closingCtaTitle.text}</h5>
            <Link to={`/${closingCtaButtonLink.uid}`} className="button xs primary">
              {closingCtaButtonText.text}
            </Link>
          </div>
        )}
      </Wrapper>
    </Section>
  );
};
