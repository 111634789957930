import React from 'react';
import { Section, Wrapper, Heading, Image, Link } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import './Instagram.scss';

export const Instagram = ({ data }) => {
  const { title, subtitle } = data;

  // const content = useStaticQuery(graphql`
  //   query InstagramQuery {
  //     allInstaNode(limit: 8) {
  //       nodes {
  //         id
  //         localFile {
  //           childImageSharp {
  //             fluid(quality: 80, maxWidth: 640) {
  //               ...GatsbyImageSharpFluid_withWebp
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  let content;

  const items = content?.allInstaNode?.nodes;
  if (!items || items.length < 1) return null;

  return (
    <Section className="instagram">
      <Wrapper>
        <Heading title={title} subtitle={subtitle} />
        <div className="instagram-items">
          {items.map((item) => {
            const { id, localFile } = item;
            return (
              <Link key={id} className="instagram-item" to={`https://www.instagram.com/p/${id}`}>
                <Link.Indicator />
                <div className="instagram-placeholder" />
                <div className="instagram-image">
                  <Image image={{ localFile }} />
                </div>
              </Link>
            );
          })}
        </div>
      </Wrapper>
    </Section>
  );
};
