import React from 'react';
import { Section, Wrapper, Scroll, Image, Mask } from 'components';
import './HeroImage.scss';

export const HeroImage = props => {
  const {
    data: { title, description, image },
  } = props;

  return (
    <Section className="hero-image">
      <div className="hero-image-overlay" />

      <div className="hero-image-background">
        <Image image={image} />
      </div>

      <div className="hero-image-content">
        <Wrapper>
          {title && <h1 className="hero-image-title">{title.text}</h1>}
          {description && <p className="hero-image-description">{description.text}</p>}
        </Wrapper>
      </div>
      <Scroll />
      <Mask />
    </Section>
  );
};
