import React from 'react';
import { Avatar, Section, Wrapper, Carousel, Image, Link, Heading, Icon } from 'components';
import { loop } from 'helpers';
import './ContentDisplay.scss';

// Carousel Settings
const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const ItemDisplay = (props) => {
  const content = props && props.content && props.content.document && props.content.document;
  const data = content && content.data;
  const slug = content && content.uid;
  if (!data) return null;
  const { title, description, thumbnail, year, location, review, role, name, photo } = data;
  return (
    <Link to={`/project/${slug}`} className="content-display-item">
      <div className="content-display-container">
        <div className="content-display-text">
          <div className="item-title">{title && title.text}</div>
          <div className="item-tags">
            <ul>
              {year && <li>{year}</li>}
              {location.name && <li>{location.name}</li>}
            </ul>
          </div>
          <div className="item-description">{description && description.text}</div>
          {review && review.text && (
            <div className="item-review">
              <div className="item-review-icon">
                <Icon type="svg " title="Quote" />
              </div>
              <div className="item-review-heading">
                <Avatar image={photo} name={name} />
                <div className="item-review-author">
                  <div className="item-review-name">{name}</div>
                  <div className="item-review-role">{role}</div>
                </div>
              </div>
              <div className="item-review-message">{review.text}</div>
            </div>
          )}
        </div>
        <div className="content-display-image">
          <Link.Indicator />
          <div className="content-display-image-container">
            <Image image={thumbnail} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export const ContentDisplay = ({ data, items }) => {
  const { title, description } = data;
  return (
    <Section className="content-display">
      <Wrapper className="content-display-heading">
        <Heading
          title={title}
          subtitle={{ text: 'Providing cleaning and waste management services.' }}
          description={description}
        />
      </Wrapper>
      <div className="content-display-items">
        <Carousel layout="Single">
          {loop(items, (item) => (
            <div key={item.key} className="slider-item">
              {ItemDisplay(item)}
            </div>
          ))}
        </Carousel>
      </div>
    </Section>
  );
};
