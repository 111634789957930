import React from 'react';
import { Section, Wrapper, Image, Heading, Link } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import { classNames, loop } from 'helpers';
import './List.scss';

const Items = ({ items }) => (
  <div className="list-items">
    {loop(items, ({ uid, type, data }) => {
      const { title, description, thumbnail } = data;

      // Fix Blog/Article type renaming
      const pageType = type === 'article' ? 'blog' : type;

      return (
        <Link key={uid} className="list-item" to={`/${pageType}/${uid}`}>
          <Link.Indicator />
          <div className="item-image">
            <Image image={thumbnail} />
          </div>
          <div className="item-content">
            <div className="item-title">{title && title.text}</div>
            <div className="item-description">{description && description.text}</div>
          </div>
        </Link>
      );
    })}
  </div>
);

export const List = ({ data }) => {
  const content = useStaticQuery(graphql`
    query ListContent {
      article: allPrismicArticle(sort: { order: DESC, fields: last_publication_date }) {
        nodes {
          uid
          type
          data {
            title {
              text
            }
            description {
              text
            }
            thumbnail {
              url
              alt
              gatsbyImageData(width: 1200, layout: CONSTRAINED, placeholder: NONE)
            }
          }
        }
      }
      project: allPrismicProject(sort: { order: DESC, fields: last_publication_date }) {
        nodes {
          uid
          type
          data {
            title {
              text
            }
            description {
              text
            }
            location {
              coordinates {
                lat: latitude
                lng: longitude
              }
              name
            }
            thumbnail {
              url
              alt
              gatsbyImageData(width: 1200, layout: CONSTRAINED, placeholder: NONE)
            }
          }
        }
      }
    }
  `);

  const { title, subtitle, description, type } = data;
  if (!type) return null;
  const contentType = type.toLowerCase();
  const items = content && content[contentType] && content[contentType].nodes;
  return (
    <Section className="list">
      <Wrapper>
        <Heading title={title} subtitle={subtitle} description={description} />
        <Items items={items} />
      </Wrapper>
    </Section>
  );
};
