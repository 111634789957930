import React, { useState } from 'react';
import { Section, Wrapper, Image, Icon } from 'components';
import { loop, classNames } from 'helpers';
import './Accordion.scss';

export const Accordion = ({ items }) => {
  const [sections, setSections] = useState({ 0: true, 1: true });

  return (
    <Section className="accordion">
      <Wrapper>
        <div className="accordion-items">
          {loop(items, item => (
            <Item
              key={item.key}
              item={item}
              index={item.index}
              isVisible={sections[item.index] === true}
              setVisible={() => setSections({ ...sections, [item.index]: !sections[item.index] })}
            />
          ))}
        </div>
      </Wrapper>
    </Section>
  );
};

const Item = props => {
  const { item, isVisible, setVisible } = props;
  if (!item) return null;
  const { section, title, subtitle, description, link, link_url: linkUrl, image } = item;

  const classes = classNames('accordion-item', { visible: isVisible });

  return (
    <div className={classes}>
      <div className="accordion-item-section" onClick={() => setVisible()}>
        <div>{section}</div>
        <Icon className="accordion-item-icon" title="ChevronDownPrimary" />
      </div>
      <div className="accordion-item-container">
        <div className="accordion-item-wrapper">
          <div className="accordion-item-image">
            <Image image={image} />
          </div>
          <div className="accordion-item-content">
            <div className="accordion-item-title">
              <h2>{title.text}</h2>
            </div>
            <div className="accordion-item-subtitle">{subtitle && subtitle.text}</div>
            <div
              className="accordion-item-description"
              dangerouslySetInnerHTML={{ __html: description && description.html }}
            />
            {link && (
              <div className="accordion-item-link">
                <a className="button primary" href={(linkUrl && linkUrl.url) || '#contact'}>
                  {link}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
