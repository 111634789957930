import React from 'react';
import { Avatar, Section, Wrapper, Heading, Image, Icon } from 'components';
import { loop } from 'helpers';
import './Quote.scss';

export const Quote = ({ data }) => {
  const { quote, author, role, photo } = data;
  return (
    <Section className="quote">
      <Wrapper>
        <div className="quote-content">
          <div className="quote-icon">
            <Icon type="svg " title="Quote" />
          </div>
          <div>
            <div className="quote-description">{quote && quote.text}</div>
            <div className="quote-author">
              <Avatar image={photo} name={author} className="quote-author-photo" />
              <div className="quote-author-name">
                {author}
                <div className="quote-author-role"> {role}</div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </Section>
  );
};
