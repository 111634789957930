import React from 'react';
import { Section, Wrapper, Heading } from 'components';
import { loop } from 'helpers';
import './Statistics.scss';

export const Statistics = ({ data, items }) => {
  const { title, subtitle, description } = data;
  return (
    <Section className="statistics">
      <Wrapper className="statistics-wrapper">
        <Heading className="vertical" title={title} subtitle={subtitle} description={description} />
        <div className="statistics-items">
          {loop(items, item => (
            <div key={item.key} className="statistics-item">
              <div className="statistics-item-title">{item.title && item.title.text}</div>
              <div className="statistics-item-description">{item.description && item.description.text}</div>
            </div>
          ))}
        </div>
      </Wrapper>
    </Section>
  );
};
