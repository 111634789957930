import React from 'react';
import { Section, Wrapper, Link, Icon } from 'components';
import Test from 'images/FourOhFour.svg';
import './FourOhFour.scss';

export const FourOhFour = () => (
  <Section className="FourOhFour">
    <Wrapper>
      <div className="FourOhFourImage">
        <img src={Test} alt="Sorry, We could not find content for the URL you clicked." />
      </div>
      <div className="FourOhFourText">
        <p>Sorry, We could not find content for the URL you clicked.</p>
        <Link to="/" className="button md round primary">
          Go back to homepage
          <Icon title="Chevron" type="svg" />
        </Link>
      </div>
    </Wrapper>
  </Section>
);
