import React from 'react';
import { Section, Wrapper, Mask, Icon, VideoBackground, Link, Scroll } from 'components';
import './HeroVideo.scss';

export const HeroVideo = ({ data }) => {
  const { title, description, video } = data;
  return (
    <Section className="hero-video">
      <VideoBackground className="hero-video-background" url={video && video.url}>
        <Mask />
      </VideoBackground>
      <div className="hero-video-container">
        <Wrapper className="hero-video-wrapper">
          <div className="hero-video-content">
            <h1>{title && title.text}</h1>
            <p>{description && description.text}</p>
          </div>
          <div className="hero-video-actions">
            <Link className="button white lg outline" to="/about-us">
              About us
            </Link>
            <Link className="button primary lg" to="/#contact">
              Get a quote
              <Icon title="Chevron" type="svg" />
            </Link>
          </div>
        </Wrapper>
      </div>
      <Scroll />
    </Section>
  );
};
