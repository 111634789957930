import React, { useState } from 'react';
import { Section, Wrapper, Link } from 'components';
import Location from 'assets/icons/Location.svg';
import './ButtonGrid.scss';

const Item = ({ item, active }) => {
  const { job_description: jobDescription, job_location: jobLocation, job_title: jobTitle, job_link: jobLink } = item;

  return (
    <div className={`${'item'} ${active && 'active-item'}`}>
      <div>
        <div className="item-title" dangerouslySetInnerHTML={{ __html: jobTitle?.html }} />
        <div className="location">
          <img className="location-icon" src={Location} alt="location icon" />
          {jobLocation?.text || 'Australia'}
        </div>
      </div>
      <div className="item-description" dangerouslySetInnerHTML={{ __html: jobDescription?.html }} />
      {jobLink?.url && (
        <Link className="button xs primary" to={jobLink?.url}>
          Learn more
        </Link>
      )}
    </div>
  );
};

export const ButtonGrid = ({ items, data }) => {
  const { careers_title: sectionTitle, sub_title: sectionSubTitle } = data;

  return (
    <Section className="button-grid">
      <Wrapper className="button-grid-wrapper">
        <div>
          <div className="grid-title" dangerouslySetInnerHTML={{ __html: sectionTitle?.html }} />
          <div className="grid--sub-title" dangerouslySetInnerHTML={{ __html: sectionSubTitle?.html }} />
        </div>
        <div className="item-container">
          {items?.map((item) => (
            <div>
              <Item key={item?.job_title?.text} item={item} />
            </div>
          ))}
        </div>
      </Wrapper>
    </Section>
  );
};
