import React from 'react';
import { Section, Carousel, Image } from 'components';
import { loop, useWindowSize } from 'helpers';
import './ImageSlider.scss';

export const ImageSlider = ({ data, items }) => (
  <Section className="image-slider">
    <div className="image-wrapper">
      <Carousel layout="Gallery">
        {loop(items, (item) => (
          <div key={item.key} className="image-slider-item">
            <div className="image-slider-item-wrapper">{item.image && <Image image={item.image} />}</div>
          </div>
        ))}
      </Carousel>
    </div>
  </Section>
);
