import React, { useState } from 'react';
import { Section, Wrapper, Icon, Mask, Image, Video as VideoPlayer } from 'components';
import { classNames } from 'helpers';
import './Video.scss';

export const Video = ({ data }) => {
  const { title, video, frame } = data;
  const [show, toggleShow] = useState(false);
  const classes = classNames('video-lightbox', { show });
  const videoUrl = show && video && video.embed_url;

  return (
    <Section className="video-slice">
      <div className="video-slice-background">
        <Image image={frame} />
      </div>

      <Wrapper className="video-wrapper">
        <h2 className="video-title">{title && title.text}</h2>
        <button type="button" className="video-play" onClick={() => toggleShow(true)}>
          <Icon title="Play" />
        </button>
      </Wrapper>

      <Mask />

      <div className={classes}>
        <div className="video-lightbox-wrapper">
          <div className="video-lightbox-overlay" onClick={() => toggleShow(false)} />
          <button type="button" className="video-close" onClick={() => toggleShow(false)}>
            <Icon title="Close" />
          </button>
          <div className="video-lightbox-container">
            <VideoPlayer url={videoUrl} />
          </div>
        </div>
      </div>
    </Section>
  );
};
